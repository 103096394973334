.pagination {
  list-style-type: none;
}

.break {
  display: flex;
  align-items: center;
}

.list-item {
  margin: 0px 5px;
  display: flex;
  align-items: center;

  &:not(:nth-last-child(2))::after {
    content: ' ';
    background: #eec643;
    width: 5px;
    display: inline-block;
    height: 5px;
    margin-left: 10px;
    border-radius: 25px;
    position: relative;
    bottom: -1px;
  }
}

.item {
  cursor: pointer;
  outline: none;
  color: #102542;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #eec643;
  }
}

.active {}

.active-item {
  padding: 0px 8px;
  background: #048ba8;
  box-shadow: 0px 0px 5px #999;
  border-radius: 25px;
  border: none;
  color: #fff;
  font-weight: 600;
}