@import url('https://fonts.googleapis.com/css?family=Nunito:400,700,bold&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import '~bootstrap/dist/css/bootstrap';

@font-face {
  font-family: 'Avenir Next Bold';
  src: url("assets/fonts/avenir_next/AvenirNextLTPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Next It';
  src: url("assets/fonts/avenir_next/AvenirNextLTPro-It.ttf") format("truetype");
}

@font-face {
  font-family: 'Avenir Next Regular';
  src: url("assets/fonts/avenir_next/AvenirNextLTPro-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Avenir Next Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}